@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?37237457');
  src: url('../font/fontello.eot?37237457#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?37237457') format('woff2'),
       url('../font/fontello.woff?37237457') format('woff'),
       url('../font/fontello.ttf?37237457') format('truetype'),
       url('../font/fontello.svg?37237457#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?37237457#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-add:before { content: '\e800'; } /* '' */
.icon-back:before { content: '\e801'; } /* '' */
.icon-bluetooth:before { content: '\e802'; } /* '' */
.icon-consommation:before { content: '\e803'; } /* '' */
.icon-date:before { content: '\e804'; } /* '' */
.icon-disconnected:before { content: '\e805'; } /* '' */
.icon-gain:before { content: '\e806'; } /* '' */
.icon-good:before { content: '\e807'; } /* '' */
.icon-green:before { content: '\e808'; } /* '' */
.icon-hamburger-menu:before { content: '\e809'; } /* '' */
.icon-hide:before { content: '\e80a'; } /* '' */
.icon-kilometrage:before { content: '\e80b'; } /* '' */
.icon-low:before { content: '\e80c'; } /* '' */
.icon-low-aio:before { content: '\e80d'; } /* '' */
.icon-mail:before { content: '\e80e'; } /* '' */
.icon-more-arrow:before { content: '\e80f'; } /* '' */
.icon-normal-alert:before { content: '\e810'; } /* '' */
.icon-notif:before { content: '\e811'; } /* '' */
.icon-notif-alert:before { content: '\e812'; } /* '' */
.icon-notif-control-alert:before { content: '\e813'; } /* '' */
.icon-on-usage:before { content: '\e814'; } /* '' */
.icon-forest:before { content: '\e815'; } /* '' */
.icon-red:before { content: '\e816'; } /* '' */
.icon-select-list-arrow:before { content: '\e817'; } /* '' */
.icon-show:before { content: '\e818'; } /* '' */
.icon-submenu-consommation:before { content: '\e819'; } /* '' */
.icon-submenu-global-view:before { content: '\e81a'; } /* '' */
.icon-submenu-pollution:before { content: '\e81b'; } /* '' */
.icon-car:before { content: '\e81c'; } /* '' */
.icon-control:before { content: '\e81e'; } /* '' */
.icon-monteur:before { content: '\e81f'; } /* '' */
.icon-control-1:before { content: '\e820'; } /* '' */
.icon-entretien:before { content: '\e821'; } /* '' */
.icon-garagistes:before { content: '\e822'; } /* '' */
.icon-logout:before { content: '\e823'; } /* '' */
.icon-mes-vehicules:before { content: '\e824'; } /* '' */
.icon-profil:before { content: '\e825'; } /* '' */
.icon-accueil:before { content: '\e826'; } /* '' */
.icon-phone:before { content: '\e827'; } /* '' */
.icon-carburant-1:before { content: '\e828'; } /* '' */
.icon-controle-technique:before { content: '\e829'; } /* '' */
.icon-submenu-globalview:before { content: '\e82d'; } /* '' */
.icon-carburant:before { content: '\e82e'; } /* '' */
.icon-gloab:before { content: '\e82f'; } /* '' */
.icon-novice-use:before { content: '\e830'; } /* '' */
.icon-apropos:before { content: '\e831'; } /* '' */
.icon-legal-notice:before { content: '\e832'; } /* '' */
.icon-useful-infos:before { content: '\e833'; } /* '' */
.icon-taux-pollution:before { content: '\e834'; } /* '' */
.icon-niveau-bas-icon:before { content: '\e835'; } /* '' */
